<template>
  <!-- 登录组件 -->
  <div class="login">
    <!-- 登录栏 -->
    <div class="login-page">
      <div class="login-block">
        <div>
          <van-form
            class="login-form"
            validate-first
            @submit="success"
            :show-error-message="false"
            validate-trigger="onChange"
          >
            <!-- 与本人关系 -->
            <van-field
              readonly
              clickable
              label="关系"
              name="user_type"
              :value="user_type"
              style="font-size:1rem"
              @click="showPicker = true"
            />
            <van-popup v-model="showPicker" position="bottom">
              <van-picker
                show-toolbar
                :columns="columns"
                @confirm="onConfirm"
                @cancel="showPicker = false"
              />
            </van-popup>
            <!-- 姓名 -->
            <van-field
              v-model="name"
              label="姓名"
              clearable
              placeholder="请输入姓名"
            />
            <!-- 登陆方式 -->
            <van-field
              readonly
              clickable
              label="登陆方式"
              :value="login_type"
              @click="showLogin = true"
            />
            <van-popup v-model="showLogin" position="bottom">
              <van-picker
                show-toolbar
                :columns="loginList"
                @confirm="checkLogin"
                @cancel="showLogin = false"
              />
            </van-popup>
            <!-- 身份证号-->
            <van-field
              v-model="id_no"
              v-if="
                login_type == '身份证' || login_type == '外国人永久居留身份证'
              "
              label="身份证"
              maxlength="18"
              clearable
              placeholder="请输入身份证号"
              :rules="validatorId"
            />
            <!-- 门诊病案号-->
            <van-field
              v-model="mz_med_id"
              v-if="login_type == '门诊病案号'"
              label="门诊病案号"
              maxlength="18"
              clearable
              placeholder="请输入门诊病案号"
              :rules="validatorMedId"
            />
            <!-- 住院病案号-->
            <van-field
              v-model="zy_med_id"
              v-if="login_type == '住院病案号'"
              label="住院病案号"
              maxlength="18"
              clearable
              placeholder="请输入住院病案号"
              :rules="validatorMedId"
            />
            <!-- 手机号 -->
            <van-field
              v-model="phone"
              clearable
              label="手机号"
              maxlength="11"
              placeholder="请输入手机号"
              :rules="validatorPhone"
            />
            <div v-if="switchs">
              <!-- 卡号-->
              <van-field
                v-model="card_no"
                clearable
                label="卡号"
                placeholder="---"
                readonly
              />
              <!-- 勾选协议 -->
              <!-- <div class="check">
                <van-checkbox
                  v-model="checked"
                  shape="square"
                ></van-checkbox>
                <span>我已阅读并同意</span>
                <span @click="agreement">《用户协议》</span>
              </div> -->
            </div>
            <!-- 登录 -->
            <div class="submit" style="margin: 16px;">
              <van-button
                class="buttonColor"
                round
                block
                type="info"
                native-type="submit"
              >
                {{ switchs ? '提交' : '查询档案' }}
              </van-button>
            </div>
          </van-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//请求绑卡接口
import { open, jd2hisPatientIdcard, jd2hisCreateCard } from 'network/service'
export default {
  name: 'login',
  data() {
    return {
      //提交数据
      switchs: false,
      //身份证
      id_no: '',
      //门诊病案号
      mz_med_id: '',
      //住院病案号
      zy_med_id: '',
      //姓名
      name: '',
      //卡号
      card_no: '',
      //手机号
      phone: '',
      //本人或他人
      user_type: '本人',
      //'本人', '配偶', '父母', '子女', '亲人', '朋友'
      columns: ['本人', '他人'],
      showPicker: false,
      //登陆方式
      login_type: '身份证',
      loginList: ['身份证', '外国人永久居留身份证', '门诊病案号', '住院病案号'],
      showLogin: false,
      //卡号类型
      card_type: '',
      //卡信息和卡类型
      card: [],
      //选择卡类型
      showCart: false,
      CartList: [],
      //协议勾选
      checked: false,
      //档案病人验证
      isSamePainet: true,
      //卡号类型
      validatorCartType: [
        {
          validator: value => {
            let vali = /\S/.test(value)
            if (!vali) {
              this.$toast('请选择卡类型')
            }
            return vali
          },
          trigger: 'onSubmit',
        },
      ],
      //身份证
      validatorId: [
        {
          validator: value => {
            // 身份证验证
            let vali =
              /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value) ||
              /^[A-Z]{3}[0-9]{12}$/.test(value)
            if (!value) {
              this.$toast('请输入身份证号')
            } else if (!vali) {
              this.$toast('身份证号格式不正确')
            }
            return vali
          },
          trigger: 'onSubmit',
        },
      ],
      //病案号
      validatorMedId: [
        {
          validator: value => {
            // 病案号验证
            if (!value) {
              this.$toast('请输入病案号')
            }
            return value
          },
          trigger: 'onSubmit',
        },
      ],
      //姓名
      validatorName: [
        {
          //姓名验证
          validator: value => {
            let vali = /^[\u4E00-\u9FA5]{1,10}$/.test(value)
            if (!value) {
              this.$toast('请输入姓名')
            } else if (!vali) {
              this.$toast('姓名格式不正确')
            }
            return vali
          },
          trigger: 'onSubmit',
        },
      ],
      //手机号
      validatorPhone: [
        {
          validator: value => {
            let vali = /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(value)
            if (!value) {
              this.$toast('请输入手机号')
            } else if (!vali) {
              this.$toast('手机号格式不正确')
            }
            return vali
          },
          trigger: 'onSubmit',
        },
      ],
    }
  },
  watch: {
    //重置表单
    id_no: function() {
      this.switchs = false
      this.card_type = ''
      this.card_no = ''
      this.CartList = []
    },
    //重置表单
    phone: function() {
      this.switchs = false
      this.card_type = ''
      this.card_no = ''
      this.CartList = []
    },
    //重置表单
    name: function() {
      this.switchs = false
      this.card_type = ''
      this.card_no = ''
      this.CartList = []
    },
  },
  methods: {
    //用户协议
    // agreement () {
    //   this.$Dialog.alert({
    //     title: '用户协议',
    //     message: '',
    //     theme: 'round-button',
    //     confirmButtonText: '知道了',
    //     confirmButtonColor: 'rgb(75, 122, 249)',
    //   }).then(() => {
    //     document.getElementsByClassName('van-dialog__message')[0].scrollTop = 0
    //   });
    // },
    //与本人关系
    onConfirm(value) {
      this.user_type = value
      this.showPicker = false
    },
    checkLogin(value) {
      this.login_type = value
      this.showLogin = false
    },
    // //卡号类型
    // onCartType (value) {
    //   if (value != '无卡') {
    //     this.card_type = value;
    //     this.showCart = false;
    //     let id = this.card.find(item => item.card_type == value)
    //     this.card_no = id.card_no
    //   } else {
    //     this.card_type = value;
    //     this.showCart = false;
    //     this.card_no = '---'
    //   }
    // },
    //验证通过
    async success() {
      if (this.switchs == false) {
        //获取人员信息
        let card = await jd2hisPatientIdcard({
          card_no:
            this.login_type == '身份证' ||
            this.login_type == '外国人永久居留身份证'
              ? this.id_no
              : this.login_type == '门诊病案号'
              ? this.mz_med_id
              : this.zy_med_id,
          card_type:
            this.login_type == '身份证' ||
            this.login_type == '外国人永久居留身份证'
              ? '3'
              : '11',
        })
        if (card.code == '0') {
          this.switchs = true
          // this.card = card.data.root.body.detail
          this.card_no = card.data.root.body.med_id
          if (
            this.login_type != '身份证' &&
            this.login_type != '外国人永久居留身份证'
          ) {
            this.id_no = card.data.root.body.card_no
          }
          this.$store.state.med_id = card.data.root.body.med_id
          if (this.name != card.data.root.body.name) {
            this.isSamePainet = false
            this.$toast('输入的姓名与档案姓名不一致')
          }
          // this.$store.state.med_id = card.data.root.body.med_id
          // console.log(this.$store.state.med_id)
          // this.card.forEach(item => {
          //   //卡类型判断
          //   this.CartList.push(item.card_type)
          // })
        } else if (
          card.msg.includes('查询患者信息未获取到门诊病人信息') &&
          card.code == '-1' &&
          (this.login_type == '身份证' ||
            this.login_type == '外国人永久居留身份证')
        ) {
          let y = new Date().getFullYear()
          let m = new Date().getMonth() + 1
          m = m < 10 ? '0' + m : m
          let d = new Date().getDate()
          d = d < 10 ? '0' + d : d
          let trade_date = y + '-' + m + '-' + d
          let res = await jd2hisCreateCard({
            sendcard_no: this.id_no,
            idno: this.id_no,
            name: this.name,
            sex: this.id_no.slice(16, 17) % 2 == 0 ? '女' : '男',
            phone: this.phone,
            trade_date: trade_date,
            birthday:
              this.id_no.slice(6, 10) +
              '-' +
              this.id_no.slice(10, 12) +
              '-' +
              this.id_no.slice(12, 14),
          })
          if (res.code == '0') {
            this.switchs = true
            this.card_no = res.data.root.body.med_id
            this.$store.state.med_id = res.data.root.body.med_id
            // this.$store.state.med_id = card.data.root.body.med_id
          } else {
            this.$toast(res.msg)
          }
          // this.CartList.push('无卡')
          // this.card_type = '无卡';
          // this.card_no = '---'
        } else {
          this.$toast(card.msg)
        }
      } else {
        if (!this.isSamePainet) {
          this.$toast('输入的姓名与档案姓名不一致')
          return
        }
        //获取openId
        let { open_id } = this.$Cookies.get()
        //卡类型判断
        let card_type = '11'
        let user_type
        // if (this.card_type == "无卡") {
        //   card_type = "0"
        // } else if (this.card_type.includes('医保')) {
        //   card_type = "1"
        // } else {
        //   card_type = "2"
        // }
        if (this.user_type == '本人') {
          user_type = '0'
        } else {
          user_type = '1'
        }
        //绑卡响应数据
        let res = await open({
          open_id,
          user_type,
          card_type,
          id_no: this.id_no,
          name: this.name,
          phone: this.phone,
          card_no: this.card_no,
        })
        this.$store.state.adopt = true
        if (res.code == '0') {
          this.$store.commit('USER_POST', { _this: this, name: 'login' })
          this.$toast(res.msg)
        } else if (res.code == '-1') {
          this.$toast(res.msg)
        } else {
          this.$toast(res.msg)
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.buttonColor {
  background: @darkColor;
  border: 1px solid @darkColor;
}
.login-page {
  height: 100vh;
  padding-top: 1rem;
  .login-block {
    display: flex;
    flex-direction: column;
    .login-form {
      /deep/.van-field {
        font-size: 1rem;
      }
    }
    .check {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      span:nth-child(2) {
        margin: 0 0.5rem;
      }
      span:last-child {
        color: @darkColor;
      }
    }
    /deep/.submit {
      margin-top: 2rem !important;
    }
  }
}
</style>
